<template>
  <div
    class="rounded-lg border border-solid border-primary mb-6 flex shadow-md"
  >
    <div
      class="cursor-move flex item-center self-stretch border-solid border-primary border-0 border-r p-4"
    >
      <feather-icon :icon="icon" svgClasses="w-4 h-4 text-primary" />
    </div>
    <div class="flex flex-wrap w-full">
      <div class="p-4 w-full">
        <div class="flex flex-wrap mb-3">
          <!-- <input type="text" class="h-12 w-full vs-inputx vs-input--input input-onboarding" data-vv-validate-on="blur"
            v-validate="'required|min:3'" danger-text="1|2|3" name="question" v-model="message" /> -->
          <textarea
            rows="2"
            class="w-full vs-inputx vs-input--input input-onboarding"
            data-vv-validate-on="blur"
            v-validate="'required|min:3'"
            danger-text="1|2|3"
            name="question"
            v-model="message"
            style="resize: none; font-family: sans-serif"
          />
          <span class="text-danger text-sm">{{
            errors.first("question")
          }}</span>
          <div class="label-onboarding">{{ $t("BotMessage") }}</div>
        </div>
        <div class="flex flex-wrap w-full mb-3">
          <div class="flex flex-wrap w-1/3">
            <input
              type="text"
              class="h-12 mr-2 w-full vs-inputx vs-input--input input-onboarding"
              data-vv-validate-on="blur"
              v-validate="'required|min:3'"
              danger-text="1|2|3"
              name="label"
              v-model="label"
            />
            <span class="text-danger text-sm">{{ errors.first("label") }}</span>
            <div class="label-onboarding">{{ $t("MessageTag") }}</div>
          </div>
          <div class="flex flex-wrap w-1/3">
            <!--entity-->
            <input
              type="text"
              class="h-12 mr-2 w-full vs-inputx vs-input--input input-onboarding"
              name="entity"
              v-model="entity"
            />
            <div class="label-onboarding flex item-center">
              {{ $t("Entity") }}
              <vx-tooltip
                :text="$t('EntityTooltip')"
                color="primary"
                class="ml-1"
              >
                <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
              </vx-tooltip>
            </div>
          </div>
          <div class="flex flex-wrap w-1/3">
            <!--intent-->
            <input
              type="text"
              class="h-12 w-full vs-inputx vs-input--input input-onboarding"
              name="intent"
              v-model="intent"
            />
            <div class="label-onboarding flex item-center">
              {{ $t("Intention") }}
              <vx-tooltip
                :text="$t('IntentionToolTip')"
                color="primary"
                class="ml-1"
              >
                <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
              </vx-tooltip>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mb-3">
          <v-select
            id="responseType_edit"
            v-model="responseType"
            :clearable="false"
            :options="filteredResponseOptions"
            name="responseType"
            class="w-full"
          />
          <div class="label-onboarding">Selecione o tipo de resposta</div>
        </div>

        <div class="flex flex-wrap mb-3">
          <div v-if="file" class="media-card">
            <div class="p-1">
              <img
                width="50"
                height="50"
                v-if="getMimetype(file).includes('image')"
                :src="tempFile(file)"
                alt="Imagem selecionada"
              />
              <div v-else class="fileIcon">
                <feather-icon icon="FileIcon" />
              </div>
            </div>

            <div class="filename">
              {{ realFileName(file) }}
            </div>

            <div class="actions">
              <vs-button
                type="flat"
                color="dark"
                icon-pack="feather"
                icon="icon-arrow-down-circle"
                class="mt-1"
                :href="tempFile(file)"
              />
              <vs-button
                type="flat"
                color="dark"
                icon-pack="feather"
                icon="icon-trash-2"
                class="mt-1"
                @click="file = null"
              />
            </div>
          </div>
          <a v-else class="mr-3" href="#_" @click="triggerAddFile">
            <feather-icon
              icon="PlusIcon"
              class=""
              svgClasses="w-3 h-3 text-primary"
            />
            Adicionar Mídia
          </a>
          <div class="ml-3 flex items-center">
            <a href="#_" @click="openWebhookPopup">
              <feather-icon
                icon="RepeatIcon"
                class=""
                svgClasses="w-3 h-3 text-primary"
              />
              Chamada Webhook
            </a>
          </div>
          <input
            id="image_file"
            type="file"
            ref="fileUpload"
            class="hidden"
            accept="*"
            @change="addFile"
          />
        </div>
        <div v-show="responseType.value == 'suggestion'">
          <div class="flex relative flex-wrap mb-3">
            <input
              type="text"
              class="h-12 w-full vs-inputx vs-input--input input-onboarding"
              name="suggestions"
              ref="suggestionsInput"
              v-model="suggestionsInput"
              @keyup="keyupInput"
              @blur="blurInput"
              maxlength="20"
            />
            <span class="font-semibold absolute floatCounter">
              {{ suggestionsInput.length }}
            </span>
            <span class="text-danger text-sm">{{
              errors.first("question")
            }}</span>
            <div class="label-onboarding">
              Opção {{ suggestionsArray.length + 1 }} ({{
                suggestionsInput.length
              }}/20)
            </div>
            <span v-if="optionsError" class="text-danger text-sm">{{
              $t("OptionsNotNull")
            }}</span>
          </div>
          <div class="flex flex-wrap mb-3" v-show="suggestionsArray.length > 0">
            <vs-chip
              closable
              color="primary mr-3"
              close-icon="close"
              v-for="(currentsuggestion, index) in suggestionsArray"
              :key="index"
              @click="removeOption(index, 'suggestionsArray')"
              >{{ currentsuggestion }}</vs-chip
            >
          </div>
        </div>
        <div v-show="responseType.value == 'subflow'">
          <div class="flex relative flex-wrap mb-3">
            <input
              type="text"
              class="h-12 w-full vs-inputx vs-input--input input-onboarding"
              name="subflows"
              ref="subflowsInput"
              v-model="subflowsInput"
              @keyup="keyupInput"
              @blur="blurInput"
              maxlength="20"
            />
            <span class="font-semibold absolute floatCounter">
              {{ subflowsInput.length }}
            </span>
            <span class="text-danger text-sm">{{
              errors.first("question")
            }}</span>
            <div class="label-onboarding">
              Opção {{ subflowsArray.length + 1 }} ({{
                subflowsInput.length
              }}/20)
            </div>
          </div>
          <span v-if="optionsError" class="text-danger text-sm">{{
            $t("OptionsNotNull")
          }}</span>
          <div class="flex flex-wrap mb-3" v-show="subflowsArray.length > 0">
            <vs-chip
              closable
              color="primary mr-3"
              close-icon="close"
              v-for="(currentsuggestion, indexChildren) in subflowsArray"
              :key="indexChildren"
              @click="removeOption(indexChildren, 'subflowsArray')"
              >{{ currentsuggestion }}</vs-chip
            >
          </div>
        </div>
        <!-- BOTÕES FINAL -->
        <div class="w-full flex flex-wrap justify-between">
          <vs-button
            class="w-full mt-6 md:mb-8 md:w-2/5"
            color="danger"
            type="border"
            @click="cancelEdition()"
          >
            Cancelar
          </vs-button>
          <vs-button
            class="w-full mt-4 text-white md:w-1/2 mt-6 mb-8"
            color="success"
            @click="saveQuestion()"
          >
            Salvar Pergunta
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import responseOptions from "./responseOptions";
import axios from "@/axios";
import mime from "mime/lite";

export default {
  name: "chatbot-question",
  components: {
    "v-select": vSelect,
  },
  props: {
    questionForm: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    indexArrayPosition: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      question: "",
      message: "",
      label: "",
      entity: "",
      intent: "",
      children: [],
      suggestions: [],
      suggestionsArray: [],
      suggestionsInput: "",
      subflowsArray: [],
      subflowsInput: "",
      responseType: {},
      responseOptions,
      optionsError: false,
    };
  },
  computed: {
    filteredResponseOptions: function () {
      let options = responseOptions;
      if (this.indexArrayPosition >= 3)
        options = options.filter((option) => option.value != "subflow");
      return options;
    },
  },
  created: function () {
    this.question = JSON.parse(JSON.stringify(this.questionForm));

    if (this.question.children) this.children = this.question.children;
    if (this.question.suggestions) this.suggestions = this.question.suggestions;

    this.file = this.question.data.file;

    this.message = Array.isArray(this.question.data.text)
      ? this.question.data.text[0]
      : this.question.data.text;
    this.label = this.question.label;
    this.entity =
      this.question.entity.length > 0
        ? this.question.entity.startsWith("@")
          ? this.question.entity.slice(1, this.question.entity.length)
          : this.question.entity
        : "";
    this.intent =
      this.question.intent.length > 0
        ? this.question.intent.startsWith("#")
          ? this.question.intent.slice(1, this.question.intent.length)
          : this.question.intent
        : "";
    this.responseType = responseOptions.find(
      (option) => option.value === this.question.input_type
    );
  },
  mounted: function () {
    if (!this.question.id || this.question.id === "") this.saveQuestion();
    if (this.question.children && this.question.children.length > 0) {
      this.mountOptionsInput("subflowsArray", "subflowsInput", "children");
    } else if (
      this.question.suggestions &&
      this.question.suggestions.length > 0
    ) {
      this.mountOptionsInput(
        "suggestionsArray",
        "suggestionsInput",
        "suggestions"
      );
    }
  },
  watch: {
    responseType: function (responseType) {
      this.suggestionsArray = [];
      this.subflowsArray = [];
      if (responseType.value === "subflow")
        this.mountOptionsInput("subflowsArray", "subflowsInput", "children");
      if (responseType.value === "suggestion")
        this.mountOptionsInput(
          "suggestionsArray",
          "suggestionsInput",
          "suggestions"
        );
    },
    subflowsArray: function (array) {
      if (this.responseType.value === "subflow" && array.length < 1)
        this.optionsError = true;
      else this.optionsError = false;
    },
    suggestionsArray: function (array) {
      if (this.responseType.value === "suggestion" && array.length < 1)
        this.optionsError = true;
      else this.optionsError = false;
    },
  },
  methods: {
    getMimetype(fileUrl) {
      return mime.getType(fileUrl) || "";
    },
    fileName(fileUrl) {
      const fileSplit = fileUrl.split("/");
      return decodeURIComponent(fileSplit[fileSplit.length - 1]);
    },
    realFileName(fileUrl) {
      const fileName = this.fileName(fileUrl);

      const fileNameParts = fileName.split(".");
      const ext = fileNameParts[fileNameParts.length - 1];

      const fileNameSplit = fileName.split("_");

      fileNameSplit.pop();

      return fileNameSplit.join("_") + "." + ext;
    },
    tempFile(fileUrl) {
      return `${process.env.VUE_APP_API_URL}p/chat/media/tempFile/${fileUrl}`;
    },
    triggerAddFile() {
      this.$refs.fileUpload.click();
    },
    async deleteFile(fileName) {
      try {
        await axios.delete(`p/chat/media/upload/${fileName}`);

        this.file = null;
      } catch (e) {
        this.$vs.notify({
          title: "Erro de Imagem",
          text: "Erro ao deletar a imagem",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }
    },
    async addFile(event) {
      const file = event.target.files[0];

      if (file) {
        try {
          this.$vs.loading();

          const formData = new FormData();
          formData.append("content", file);
          formData.append("filename", file.name);

          const res = await axios.post("p/chat/media/upload", formData);

          this.file = res.data.data;
        } catch (e) {
          this.$vs.notify({
            title: "Erro de Imagem",
            text: "Erro ao fazer upload da imagem",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }
    },
    openWebhookPopup() {
      this.$emit("open-webhook-popup", this.question.id);
    },
    mountOptionsInput(inputArray, inputField, prop) {
      const self = this;
      const chipEnter = (input) => {
        self[inputArray].push(input);
        self[inputField] = "";
      };

      if (self[prop].length > 0) {
        self[prop].forEach((item) => {
          chipEnter(prop === "suggestions" ? item : item.id);
        });
      }
    },
    keyupInput(e) {
      if (e.keyCode === 13 && e.target.value) {
        this[`${e.target.name}Array`].push(e.target.value);
        this[`${e.target.name}Input`] = "";
      }
    },
    blurInput(e) {
      if (e.target.value) {
        this[`${e.target.name}Array`].push(e.target.value);
        this[`${e.target.name}Input`] = "";
      }
    },
    removeOption(index, array) {
      const self = this;
      const removeSubFlow = async () => {
        self[array].splice(index, 1);
      };
      if (array === "subflowsArray") {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: "Deletar subfluxo",
          text: "Deseja deletar o subfluxo? Isto irá excluir permanentemente o subfluxo.",
          accept: removeSubFlow,
          acceptText: this.$t("Delete"),
          cancelText: this.$t("Cancel"),
        });
      } else {
        this[array].splice(index, 1);
      }
    },
    async cancelEdition() {
      this.responseType = {};
      this.suggestionsArray = [];
      this.subflowsInput = [];
      this.subflowsArray = [];
      this.suggestionsInput = [];

      this.$emit("cancel-edit");
    },
    saveQuestion() {
      let newQuestion = JSON.parse(JSON.stringify(this.question));

      newQuestion.input_type = this.responseType.value;

      if (!newQuestion.id || newQuestion.id === "") {
        let m = new Date();
        m.setMonth(m.getMonth() + Math.floor(Math.random() * 2) + 100);
        newQuestion.id =
          String(Math.floor(Math.random() * 2000)) +
          String(m.getTime() + Math.floor(Math.random() * 2) + 10000);
      }

      if (
        (newQuestion.input_type === "suggestion" &&
          this.suggestionsArray.length < 1) ||
        (newQuestion.input_type === "subflow" && this.subflowsArray.length < 1)
      )
        this.optionsError = true;
      else this.optionsError = false;

      if (!this.optionsError && this.message.length >= 3) {
        if (
          Array.isArray(this.questionForm.data.text) &&
          this.message === this.questionForm.data.text[0]
        )
          newQuestion.data.text = this.questionForm.data.text;
        else newQuestion.data.text = this.message;

        if (this.file === this.questionForm.data.file) {
          newQuestion.data.file = this.questionForm.data.file;
        } else if (this.file) {
          newQuestion.data.file = this.file;
        } else {
          delete newQuestion.data.file;
        }

        newQuestion.label = this.label;
        newQuestion.entity =
          this.entity.length > 0
            ? this.entity.startsWith("@")
              ? this.entity
              : `@${this.entity}`
            : "";
        newQuestion.intent =
          this.intent.length > 0
            ? this.intent.startsWith("#")
              ? this.intent
              : `#${this.intent}`
            : "";

        if (this.suggestionsArray.length > 0) {
          newQuestion.suggestions = this.suggestionsArray;
        } else delete newQuestion.suggestions;

        if (this.subflowsArray.length > 0) {
          newQuestion.children = this.subflowsArray.map((childId) => {
            let child = { data: [], id: childId };
            const indexChildAlreadyExists = this.children.findIndex(
              (childItem) => childItem.id === childId
            );
            if (indexChildAlreadyExists != -1)
              child = this.children[indexChildAlreadyExists];

            return { ...child };
          });
          newQuestion.suggestions = this.subflowsArray;
        } else delete newQuestion.children;

        newQuestion.author = "assistant";

        let custom = false;
        const verifyQuestionForm = JSON.parse(
          JSON.stringify(this.questionForm)
        );
        const verifyNewQuestion = JSON.parse(JSON.stringify(newQuestion));
        delete verifyQuestionForm.children;
        delete verifyNewQuestion.children;

        const isCustom = (arrayForm, arrayQuestion) => {
          Object.entries(arrayQuestion).forEach((entrie) => {
            if (entrie[0] === "children") return;
            if (Array.isArray(entrie[1])) {
              if (!Array.isArray(arrayForm[entrie[0]])) custom = true;
              else if (entrie[1].length != arrayForm[entrie[0]].length)
                custom = true;
              else
                entrie[1].forEach((itemArray) => {
                  if (
                    arrayForm[entrie[0]] &&
                    Array.isArray(arrayForm[entrie[0]]) &&
                    !arrayForm[entrie[0]].includes(itemArray)
                  )
                    custom = true;
                });
            } else if (typeof entrie[1] === "object")
              isCustom(arrayForm[entrie[0]], entrie[1]);
            else if (arrayForm[entrie[0]] !== entrie[1]) custom = true;
          });
        };

        isCustom(verifyQuestionForm, verifyNewQuestion);
        this.$emit("save-question", this.questionIndex, newQuestion, custom);
      } else {
        this.error;
      }
    },
  },
};
</script>

<style lang="scss">
.media-card {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  background-color: #9c999979;
  border: 1px solid #6e6e6e27;
  border-radius: 5px;

  .fileIcon {
    background-color: #8a868627;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filename {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 8pt;
  }

  .actions {
    display: flex;
  }
}

.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
}

.input-onboarding {
  padding-left: 8px;
  font-size: 1rem;
}

.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}

.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}
textarea.vs-inputx {
  height: 62px;
  padding-top: 10px;
}
</style>
